import { GetAllTemplate } from "@/api/OrderInfoApi";
import { reactive } from "vue";
export class OrderTemplateService {
    static async GetAllTemplateList() {
        const result = (await GetAllTemplate({})).resultData;
        this.TemplateList.splice(0, this.TemplateList.length);
        for (let i = 0; i < result.length; i++) {
            this.TemplateList.push(result[i]);
        }
        return this.TemplateList;
    }
    static GetLastTemplate() {
        if (this.TemplateList.length > 0) {
            return this.TemplateList[this.TemplateList.length - 1];
        }
        return null;
    }
}
OrderTemplateService.TemplateList = reactive(new Array());
