import { SkuItem } from "@/services/Skus/SkuModel";
import SkuServices from "@/services/Skus/SkuServices";
import SpecService from "@/services/Specs/SpecServices";
import eventBus from "@/utils/eventBus";
import { onInitSkused, onSkuError, specChanged } from "@/utils/eventBusConst";
import { computed, ref } from "vue";
export default function skuTable(props, skus, specs, emit) {
    const pageSize = ref(10);
    const pageNum = ref(1);
    const pageDatas = computed(() => {
        return skus.slice((pageNum.value - 1) * pageSize.value, pageNum.value * pageSize.value);
    });
    const handleCurrentChange = (val) => {
        pageNum.value = val;
    };
    const getInputFocus = (event) => {
        if (event.currentTarget.value == '0') {
            event.currentTarget.select();
        }
    };
    eventBus.on(specChanged, (newSpecs) => {
        specs = SpecService.GetSpecs();
        initSkus();
    });
    eventBus.off(onSkuError);
    eventBus.on(onSkuError, () => {
        const errors = SkuServices.errors;
        for (let i = 0; i < skus.length; i++) {
            for (let j = 0; j < errors.length; j++) {
                if (errors[j].id == skus[i].id) {
                    pageNum.value = i == 0 ? 1 : Math.ceil(i / (pageSize.value));
                    return;
                }
            }
        }
    });
    const initSkus = () => {
        copyedSpecs = JSON.parse(JSON.stringify(skus));
        SkuServices.ClearSkus();
        skus = SkuServices.GetSkus();
        for (let i = 0; i < specs.length; i++) {
            mergeSkusAndSpecs(specs[i], i + 1);
        }
        eventBus.emit(onInitSkused);
    };
    let copyedSpecs;
    const getOldSkuRow = (newSpecItems) => {
        for (let i = 0; i < copyedSpecs.length; i++) {
            const specList = copyedSpecs[i].specList;
            if (!specList) {
                continue;
            }
            const equires = new Array();
            for (let j = 0; j < specList.length; j++) {
                equires.push(false);
                for (let z = 0; z < newSpecItems.length; z++) {
                    if (newSpecItems[z].id == specList[j].id) {
                        equires.splice(j, 1, true);
                    }
                }
            }
            if (equires.indexOf(false) < 0) {
                return copyedSpecs[i];
            }
        }
        return new SkuItem();
    };
    const mergeSkusAndSpecs = (specItem, layerIndex) => {
        const insertItem = new Array();
        if (skus.length == 0) {
            specItem.contentList.forEach(x => {
                const newSkuItem = new SkuItem();
                newSkuItem.specList = new Array();
                newSkuItem.specList.push(x);
                insertItem.push(newSkuItem);
            });
            insertItem.forEach(x => {
                setSkuAttr(x);
                SkuServices.AddSkus(x);
            });
            return;
        }
        let skusLength = skus.length;
        for (let i = 0; i < skusLength; i++) {
            if (skus[i].specList.length == layerIndex) {
                continue;
            }
            const copyRow = JSON.parse(JSON.stringify(skus[i]));
            for (let j = 0; j < specItem.contentList.length; j++) {
                if (j == 0) {
                    skus[i].specList.push(specItem.contentList[0]);
                    // skus[i].specContentIds = skus[i].specContentIds.concat(`,${specItem.contentList[0].id}`)
                    setSkuAttr(skus[i]);
                    continue;
                }
                const newSkuItem = new SkuItem();
                copyRow.specList.forEach(s => {
                    for (let i = 0; i < specs.length; i++) {
                        let hasContent = false;
                        for (let j = 0; j < specs[i].contentList.length; j++) {
                            if (specs[i].contentList[j].id == s.id) {
                                hasContent = true;
                                newSkuItem.specList.push(specs[i].contentList[j]);
                                break;
                            }
                        }
                        if (hasContent)
                            break;
                    }
                });
                newSkuItem.specList.push(specItem.contentList[j]);
                setSkuAttr(newSkuItem);
                SkuServices.InsertSku(i + j, newSkuItem);
                skusLength = skus.length;
            }
        }
    };
    const setSkuAttr = (skuItem) => {
        const oldSku = getOldSkuRow(skuItem.specList);
        if (oldSku.id || oldSku.specContentIds) {
            skuItem.id = oldSku.id;
            skuItem.stock = oldSku.stock;
            skuItem.price = oldSku.price;
            skuItem.originalPrice = oldSku.originalPrice;
            skuItem.productSn = oldSku.productSn;
            skuItem.selfSalePrice = oldSku.selfSalePrice;
            skuItem.enableStatus = oldSku.enableStatus;
            skuItem.price = oldSku.price;
            skuItem.platformPricingPrice = oldSku.platformPricingPrice;
            skuItem.platformPricingSalePrice = oldSku.platformPricingSalePrice;
        }
    };
    const handleChangeInput = (prop, index, val) => {
        emit("onInputChanged", prop, index + pageSize.value * (pageNum.value - 1), val);
    };
    const tableIndex = (index) => {
        return (pageNum.value - 1) * pageSize.value + index + 1;
    };
    return {
        pageSize,
        pageNum,
        pageDatas,
        handleCurrentChange,
        handleChangeInput,
        tableIndex,
        getInputFocus
    };
}
