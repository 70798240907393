import { GetProductSupplierList } from '@/api/GoodsApi';
import { reactive } from 'vue';
export default class ProductSupplierService {
    /**
     *
     * @returns 生产厂家列表
     */
    static async GetProductSupplierListAsync() {
        this.productSupplierList.splice(0, this.productSupplierList.length);
        const result = await GetProductSupplierList('');
        result.resultData.forEach((i) => {
            this.productSupplierList.push(i);
        });
        return this.productSupplierList;
    }
    static GetProductSupplierList() {
        return this.productSupplierList;
    }
    static GetProductSupplierName(productSupplierId) {
        let name = '-';
        this.productSupplierList.forEach((i) => {
            if (i.id == productSupplierId) {
                name = i.name || '-';
            }
        });
        return name;
    }
}
ProductSupplierService.productSupplierList = reactive(new Array());
