import MerchantService from "@/services/Merchant/MerchantService";
import SkuServices from "@/services/Skus/SkuServices";
import SpecService from "@/services/Specs/SpecServices";
import { defineComponent, onMounted, reactive, watch } from "vue";
import skuTable from "./skuTable";
export default defineComponent({
    props: {
        showCloumns: {
            type: Object,
            required: true,
            default: () => {
                return new Array();
            },
        },
        readonly: {
            type: Boolean,
            default: () => {
                return false;
            },
        },
    },
    emits: ["onInputChanged"],
    setup(props, { emit }) {
        const specs = SpecService.GetSpecs();
        const skus = SkuServices.GetSkus();
        const { pageSize, pageNum, pageDatas, handleCurrentChange, handleChangeInput, tableIndex, getInputFocus, } = skuTable(props, skus, specs, emit);
        const errors = SkuServices.errors;
        const errorList = reactive(new Array());
        watch(errors, () => {
            errorList.splice(0, errorList.length);
            for (let i = 0; i < errors.length; i++) {
                errorList.push(errors[i].id + "-" + errors[i].key);
            }
        });
        onMounted(() => {
            MerchantService.ShopDetailsAsync();
        });
        const curHasError = (row, key) => {
            for (let i = 0; i < errors.length; i++) {
                if (errors[i].id == row.id && errors[i].key == key) {
                    return true;
                }
            }
            return false;
        };
        return {
            errors,
            specs,
            skus,
            pageSize,
            pageNum,
            pageDatas,
            handleCurrentChange,
            handleChangeInput,
            tableIndex,
            getInputFocus,
            curHasError,
            errorList,
        };
    },
});
