import { Get, Post } from "./Request";
// 组件内容
export class TemplateModule {
}
// 订单模板
export class OrderTemplate {
}
// 收件信息模板
export class ReceiveInfoTemp {
}
// 查询所有可用模板
export function GetAllTemplate(data) {
    return Get('/order/config/template/listAll', data);
}
// 创建下单模板
export function PostOrderTemplate(data) {
    return Post('/order/config/template/create', data);
}
// 编辑下单模板
export function EditOrderTemplate(data) {
    return Post('/order/config/template/edit', data);
}
// 获取下单模板列表
export function GetOrderTemplateList(data) {
    return Post('/order/config/template/list', data);
}
// 根据id获取模板详情
export function GetOrdetTmpDetail(data) {
    return Get(`/order/config/template/${data.id}`);
}
// 获取订单组件列表
export function GetOrderCompList(data) {
    return Post('/order/config/module/list', data);
}
