import { defineComponent } from "vue";
import Upload from "@/components/upload/GoUpload.vue";
export default defineComponent({
    components: {
        Upload,
    },
    props: {
        specItem: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return {};
    },
});
